<template>
  <div class="instant-link">
    <div class="instant-link__icon">
      <img :src="icon" />
    </div>
    <div class="instant-link__space">
      <div>
        <h3 class="instant-link__title">{{ title }}</h3>
        <div class="instant-link__text">{{ text }}</div>
      </div>
      <LangRouterLink v-if="linkRoute" :to="linkRoute" class="link instant-link__link">
        {{ linkLabel }}
        <Icon id="arrow" />
      </LangRouterLink>
      <LangRouterLink v-else-if="buttonRoute" :to="{ name: buttonRoute }" class="button -dark -link -space">
        {{ buttonLabel }}
      </LangRouterLink>
      <a v-else-if="buttonLink" :href="buttonLink" target="_blank" class="button -dark -link -space">
        {{ buttonLabel }}
      </a>
      <button v-else-if="buttonAction" class="button -dark -space" @click="buttonAction">{{ buttonLabel }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstantLink",
  props: {
    icon: {
      type: String
    },
    title: {
      type: String
    },
    text: {
      type: String
    },
    linkLabel: {
      type: String
    },
    linkRoute: {
      type: Object
    },
    buttonLabel: {
      type: String
    },
    buttonLink: {
      type: String
    },
    buttonRoute: {
      type: String
    },
    buttonAction: {
      type: Function
    }
  }
};
</script>
