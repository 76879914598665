<template>
  <div :class="{ '-loading': !isDataReady || !$page }">
    <template v-if="isDataReady && $page">
      <HeaderShow v-if="!$device.customtablet" object="study" :title="study.title" :date="study.publishedAt"
        :business-name="study.companyName" :destination="'studyIndex'" />

      <div class="single">
        <div class="single__left">
          <HeaderShow v-if="$device.customtablet" object="study" :title="study.title" :date="study.publishedAt"
            :business-name="study.companyName" :destination="'studyIndex'" />

          <StudySideBar v-if="!$device.customlaptop" :logo="study.logo" :description="study.description" />

          <div class="toggle-list">
            <div class="toggle-list__item">
              <div class="toggle-list__header" tabindex="0" role="button" :aria-pressed="isProblemOpen"
                @click="isProblemOpen = !isProblemOpen">
                <h2 class="toggle-list__title">
                  {{ getLabel('problem') }}
                </h2>
                <button class="toggle-list__button" :aria-label="getToggleAria(isProblemOpen)">
                  <Icon :id="getToggleId(isProblemOpen)" />
                </button>
              </div>

              <DropDown :is-open="isProblemOpen">
                <div class="toggle-list__dropdown">
                  <h3 class="toggle-list__subtitle">{{ $page.subLabels.problem }}</h3>
                  <ul class="toggle-list__dropdown-list">
                    <li v-for="problem in study.problem" :key="problem.key" class="toggle-list__dropdown-item">
                      {{ problem.attributes.item[$lang.current] }}
                    </li>
                  </ul>

                  <h3 class="toggle-list__subtitle">{{ $page.subLabels.objective }}</h3>
                  <ul class="toggle-list__dropdown-list">
                    <li v-for="objective in study.objective" :key="objective.key" class="toggle-list__dropdown-item">
                      {{ objective.attributes.item[$lang.current] }}
                    </li>
                  </ul>
                </div>
              </DropDown>
            </div>

            <div class="toggle-list__item">
              <div class="toggle-list__header" tabindex="0" role="button" :aria-pressed="isSolutionOpen"
                @click="isSolutionOpen = !isSolutionOpen">
                <h2 class="toggle-list__title">
                  {{ getLabel('solution') }}
                </h2>
                <button class="toggle-list__button" :aria-label="getToggleAria(isSolutionOpen)">
                  <Icon :id="getToggleId(isSolutionOpen)" />
                </button>
              </div>

              <DropDown :is-open="isSolutionOpen">
                <div class="toggle-list__dropdown">
                  <h3 class="toggle-list__subtitle">{{ $page.subLabels.solution }}</h3>
                  <RawHtml :html="study.solution" />
                  <h3 class="toggle-list__subtitle">{{ $page.subLabels.principle }}</h3>
                  <RawHtml :html="study.principle" />
                  <template v-if="study.ux">
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.ux }}</h3>
                    <RawHtml :html="study.ux" />
                  </template>
                  <h3 class="toggle-list__subtitle">{{ $page.subLabels.ai }}</h3>
                  <RawHtml :html="study.ai" />
                </div>
              </DropDown>
            </div>

            <div class="toggle-list__item">
              <div class="toggle-list__header" tabindex="0" role="button" :aria-pressed="isBenefitOpen"
                @click="isBenefitOpen = !isBenefitOpen">
                <h2 class="toggle-list__title">
                  {{ getLabel('benefit') }}
                </h2>
                <button class="toggle-list__button" :aria-label="getToggleAria(isBenefitOpen)">
                  <Icon :id="getToggleId(isBenefitOpen)" />
                </button>
              </div>

              <DropDown :is-open="isBenefitOpen">
                <div class="toggle-list__dropdown">
                  <h3 class="toggle-list__subtitle">{{ $page.subLabels.impact }}</h3>

                  <RawHtml v-for="impact in study.impact" :key="impact.key"
                    :html="impact.attributes.item[$lang.current]" />
                  <template v-if="study.challenge">
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.challenge }}</h3>
                    <RawHtml :html="study.challenge" />
                  </template>
                </div>
              </DropDown>
            </div>

            <div class="toggle-list__item">
              <div class="toggle-list__header" tabindex="0" role="button" :aria-pressed="isSuccessOpen"
                @click="isSuccessOpen = !isSuccessOpen">
                <h2 class="toggle-list__title">
                  {{ getLabel('success') }}
                </h2>
                <button class="toggle-list__button" :aria-label="getToggleAria(isSuccessOpen)">
                  <Icon :id="getToggleId(isSuccessOpen)" />
                </button>
              </div>

              <DropDown :is-open="isSuccessOpen">
                <div class="toggle-list__dropdown">
                  <template v-if="study.team">
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.team }}</h3>
                    <RawHtml :html="study.team" />
                  </template>
                  <template v-if="study.codevelopment">
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.codevelopment }}</h3>
                    <RawHtml :html="study.codevelopment" />
                  </template>
                  <template v-if="study.funding">
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.funding }}</h3>
                    <RawHtml :html="study.funding" />
                  </template>
                  <template v-if="study.actualStep.length">
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.actualStep }}</h3>
                    <p v-for="actualStep in study.actualStep" :key="actualStep.key">
                      {{ actualStep.attributes.item[$lang.current] }}
                    </p>
                  </template>
                  <template v-if="study.nextStep.length">
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.nextStep }}</h3>
                    <p v-for="nextStep in study.nextStep" :key="nextStep.key">
                      {{ nextStep.attributes.item[$lang.current] }}
                    </p>
                  </template>
                </div>
              </DropDown>
            </div>

            <div v-if="hasContext" class="toggle-list__item">
              <div class="toggle-list__header" tabindex="0" role="button" :aria-pressed="isContextOpen"
                @click="isContextOpen = !isContextOpen">
                <h2 class="toggle-list__title">
                  {{ getLabel('context') }}
                </h2>
                <button class="toggle-list__button" :aria-label="getToggleAria(isContextOpen)">
                  <Icon :id="getToggleId(isContextOpen)" />
                </button>
              </div>

              <DropDown :is-open="isContextOpen">
                <div class="toggle-list__dropdown">
                  <h3 v-if="study.usage" class="toggle-list__subtitle">{{ $page.subLabels.usage }}</h3>
                  <RawHtml :html="study.usage" />
                  <h3 v-if="study.client" class="toggle-list__subtitle">{{ $page.subLabels.client }}</h3>
                  <RawHtml :html="study.client" />
                  <h3 v-if="study.opportunity" class="toggle-list__subtitle">{{ $page.subLabels.opportunity }}</h3>
                  <RawHtml :html="study.opportunity" />
                </div>
              </DropDown>
            </div>
          </div>
        </div>

        <div v-if="$device.customlaptop" class="single__right">
          <div :class="['single__sticky', { '-auth': isAuth }]">
            <StudySideBar :logo="study.logo" :description="study.description" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { StudySideBar, HeaderShow } from '@/components/partials'
import { getMeta } from '@/helpers/metaHelpers'

export default {
  name: 'Study',
  beetPage: 'studyshow',
  components: { StudySideBar, HeaderShow },
  props: {
    studySlug: { type: String, require: true }
  },
  metaInfo() {
    if (this.study.seo) {
      const { seo } = this.study
      if (!seo.title) seo.title = `${this.study.title} | Vitrine - IA`
      if (!seo.image) seo.image = `${window.location.origin}/images/seo.jpg`
      return getMeta(seo)
    }
  },
  data() {
    return {
      isDataReady: false,
      isProblemOpen: true,
      isSolutionOpen: true,
      isBenefitOpen: false,
      isSuccessOpen: false,
      isContextOpen: false
    }
  },
  computed: {
    study() {
      const id = this.$store.getters.studySlugMap[this.studySlug]
      return this.$beet.studies[id]
    },
    isAuth() {
      return this.$store.state.user.isAuth
    },
    hasContext() {
      return !(!this.study.usage && !this.study.client && !this.study.opportunity)
    }
  },
  beforeMount() {
    if (!this.study) this.$router.replace({ name: 'not-found' })
  },
  created() {
    const id = this.$store.getters.studySlugMap[this.studySlug]
    const axiosConfig = { url: `/studies/${id}?lang=${this.$lang.current}`, headers: {} }

    const parseStudy = ({ data }) => data

    this.$store.dispatch('fetchSingle', ['studies', id, axiosConfig, parseStudy]).then(() => {
      this.isDataReady = true
    })
  },
  methods: {
    getLabel(key) {
      return this.$device.laptop ? this.$page.labels[key] : this.$page.labels[`${key}Short`]
    },
    getToggleId(isOpen) {
      return isOpen ? 'less' : 'more'
    },
    getToggleAria(isOpen) {
      return isOpen ? this.$t('app.ariaLabel.less') : this.$t('app.ariaLabel.more')
    }
  }
}
</script>
