<template>
  <div :class="{ '-loading': !$page }">
    <template v-if="$page">
      <section :class="['header', '-home', { '-auth': isAuth }]">
        <img class="header__texture" src="/images/banner_texture.jpg" />
        <img class="header__img" src="/images/home_banner_img.png" />
        <div class="header__content">
          <div>
            <h2 v-scroll:reveal="{ delay: 650 }" class="header__title -home">{{ $page.header.title }}</h2>
            <p v-scroll:reveal="{ delay: 700 }" class="header__text">{{ $page.header.subtitle }}</p>
          </div>

          <LangRouterLink v-scroll:reveal="{ delay: 800, offset: 0 }" :to="{ name: 'businessIndex' }"
            class="header__link -home button">{{ $page.header.linkLabel }}
          </LangRouterLink>
        </div>
        <LangRouterLink v-if="this.$beet.options.general.video" v-scroll:reveal="{ delay: 650, offset: 0 }"
          class="header__video-modal" :to="openVideoModal">
          <Icon id="miria_stars" />
          {{ $page.header.videoLabel }}
        </LangRouterLink>
      </section>

      <FunctionalityCta v-scroll:reveal="{ delay: 100 }" :title="$page.functionnality.title"
        :subtitle="$page.functionnality.subtitle" :link-label="$page.functionnality.linkLabel" class="-home" />

      <section class="section">
        <div v-scroll:reveal="{ delay: 100 }" class="section__header">
          <span v-if="$device.customtablet" class="section__header-badge"><img src="/badges/study.svg" alt=""
              class="section__header-badgeimg" /></span>

          <div>
            <h2 class="section__title -bigger">{{ $page.studies.title }}</h2>
            <p>{{ $page.studies.subtitle }}</p>
          </div>

          <LangRouterLink :to="{ name: 'studyIndex' }" class="section__header-link link"><span
              v-if="!$device.customtablet" class="section__header-badge"><img src="/badges/study.svg" alt=""
                class="section__header-badgeimg" /></span>{{ $page.studies.linkLabel }}
            <Icon id="arrow" />
          </LangRouterLink>
        </div>

        <div class="study-card-list">
          <StudyHomeCard v-for="(study, i) in $page.studies.items" :id="study" :key="study"
            v-scroll:reveal="{ delay: i * 150 }" class="study-card-list__item" />
        </div>
      </section>

      <section v-scroll:reveal="{ delay: 100 }" class="block-tools">
        <div class="block-tools__content">
          <h2 class="block-tools__title">{{ $page.tools.title }}</h2>
          <p class="block-tools__subtitle">{{ $page.tools.subtitle }}</p>
          <LangRouterLink :to="{ name: 'maturity' }" class="block-tools__link button">
            {{ $page.tools.linkLabel }}
          </LangRouterLink>
        </div>
      </section>

      <section class="block-subscribe">
        <InstantLink icon="/badges/speakerphone.svg" :title="$page.subscribe.title" :text="$page.subscribe.subtitle"
          :button-label="$page.subscribe.linkLabel"
          button-link="https://app.cyberimpact.com/clients/48070/subscribe-forms/647D93F0-265A-4C9A-878D-C96CE69258B8" />
      </section>
    </template>
  </div>
</template>

<script>
import {FunctionalityCta, InstantLink} from '@/components/partials'
import {StudyHomeCard} from '@/components/cards'
import {PageMetaMixin} from '@/mixins'

export default {
  name: 'Home',
  beetPage: 'home',
  components: {FunctionalityCta, InstantLink, StudyHomeCard},
  mixins: [PageMetaMixin],
  computed: {
    isAuth() {
      return this.$store.state.user.isAuth
    },
    openVideoModal() {
      return {
        name: this.$route.name,
        query: {...this.$route.query, video: true}
      }
    }
  },
  mounted() {
    if(!this.$beet.options.general.video) {
      document.documentElement.style.setProperty('--banner-button-height', '0px');
    }
  }
}
</script>
