export default {
  companyName: 'Vitrine IA Québec',
  portalButton: 'Portail Export',
  logoutButton: 'Déconnexion',
  by: 'Par',
  updated: 'Fiche mise à jour le',
  publishedStudy: 'Publiée le',
  pending: 'Recherche en cours',
  back: 'Retour',
  previous: 'Précédent',
  next: 'Suivant',
  submit: 'Soumettre',
  send: 'Envoyer',
  yes: 'Oui',
  no: 'Non',
  select: "Sélectionner une option",
  globalError: "Une erreur est survenue. Veuillez réessayer plus tard ou nous contacter si le problème persiste.",
  search: "Rechercher votre organisation",
  vitrine: "Ma Vitrine",
  other: " et {nb} autre région | et {nb} autres régions",
  profile: "Ce profil est le vôtre?",
  miria: {
    description: 'La version bêta, lancée le 11 septembre, est désormais accessible en version restreinte.',
    highlight: 'Nous vous invitons à inscrire votre entreprise et à découvrir l’expérience Miria.</br> Pour recevoir le lien d’inscription par courriel:',
    note: '<a href="mailto:miria@boitebeet.com">Contactez-nous</a>',
    title: 'L’agent IA <em>Miria</em> : une nouvelle fonctionnalité pour valoriser les entreprises innovantes québécoises en IA.',
  },
  nav: {
    newsletter: 'Vitrine IA est présentement en évolution. <a href="[URL]" target="_blank">Abonnez-vous à notre infolettre</a> pour être informé en primeur des nouveautés.',
    repertory: 'Répertoire',
    studies: 'Études',
    ecosystem: 'Écosystème',
    funding: 'Recherche de financement',
    auth: 'Vous êtes connecté au Portail Export',
    backStudies: 'Retour aux études',
    backBusinesses: 'Retour au répertoire',
    backHome: "Retour à l'accueil",
    scrollUp: 'Haut de la page',
    tools: 'Outils',
    maturity: 'Évaluation de la maturité'
  },
  footer: {
    subscriptionButton: "S'inscrire à l'infolettre",
    actorTypes: "Types d'acteur",
    categories: 'Types de services',
    resources: 'Outils',
    maturity: 'Évaluation de la maturité',
    ethic: 'Évaluation éthique (à venir)',
    terms: 'Conditions d\'utilisation',
    glossary: 'Glossaire',
    partners: 'Nos partnenaires financiers'
  },
  links: {
    sectorCard: 'Explorer ce secteur',
    studyCard: 'Voir cette étude',
    businessSingle: "Consulter la fiche d'entreprise",
    businessContact: "Contacter l'organisation",
    businessWebsite: "Visitez le site de l'organisation",
    studySingle: "Voir l'étude",
    storySingle: "En savoir plus",
    ecosystem: 'Découvrez la Vitrine IA Québec',
    details: 'de détails'
  },
  filters: {
    isStartup: 'Est une Jeune pousse',
    title: 'Filtres',
    filterButton: 'Filtres',
    region: 'Régions',
    placeholder: "Rechercher par entreprise ou titre de l'étude.",
    more: 'Voir plus de résultats',
    noResult: 'Aucun résultat',
    results: 'résultat(s)',
    display: 'Voir',
    reset: 'Effacer',
    tech: 'Technologies d’IA'
  },
  object: {
    business: 'Entreprise',
    study: 'Étude',
    sector: 'Secteur',
    appSector: "Secteurs d'application",
    activitySector: "Secteurs d'activité",
    actorType: "Types d'acteur",
    businessTag: "Technologies d'IA",
    region: 'Régions',
    category: 'Expertises',
    startup: 'Jeune pousse',
    startupStart: 'Jeune pousse créée en {year}',
    startups: 'Jeunes pousses'
  },
  cookies: {
    mainButton: 'Accepter',
    secondButton: 'Accepter seulement les témoins nécessaires',
    content:
      'Ce site Web utilise des témoins (cookies) sur votre appareil. Nous utilisons ces informations afin d’améliorer et de personnaliser votre expérience de navigation. Pour en savoir plus, consultez nos ',
    linkLabel: "conditions d'utilisation"
  },
  ariaLabel: {
    closeMenu: 'Fermer le menu',
    openFilter: 'Ouvrir les filtres',
    closeFilter: 'Fermer les filtres',
    search: 'Lancer une recherche',
    closeConnexion: 'Fermer la connexion',
    closeReset: 'Fermer la réinitialisation',
    closeModal: 'Fermer la fenêtre modale',
    nextPage: 'Charger la page suivante',
    previousPage: 'Charger la page précédente',
    more: 'Voir plus',
    less: 'Voir moins',
    footerLogo: 'Propulsé par Forum IA Québec',
    partnerLogo: 'Logo du gouvernement du Québec',
    print: 'Imprimer cette page',
    share: 'Partager cette page',
    shareMore: 'Ouvrir les options de partage',
    contact: 'Contactez-nous',
    switch: 'Switch to English'
  },
  instant: {
    link: {
      title: 'Obtenez un portrait rapide des organisations du répertoire',
      text: 'Notre coup d’oeil vous permet d’explorer des statistiques et des résumés par région.',
      linkLabel: 'Consulter le coup d’oeil',
    },
    menu: {
      complete: 'Répertoire complet',
      overview: 'En un coup d’œil'
    },
    header: {
      title: 'Coup d’œil sur le répertoire',
      subtitle: 'Obtenez un portrait concis et rapide des organisations présentes dans notre répertoire de l’écosystème en IA'
    },
    footer: {
      title: 'Recherchez parmi toutes les organisations québécoises en IA',
      text: 'Utilisez les filtres de recherche de notre répertoire pour trouver celle qui répond à vos besoins.',
      link: 'Consulter le répertoire'
    },
    total: 'Total des organisations dans le répertoire',
    available: 'Selon les données disponibles',
    startups: 'Jeunes pousses répertoriées',
    stats: 'Obtenir les statistiques de la région',
    select: 'Sélectionnez une région sur la carte pour en obtenir les statistiques spécifiques.',
    missing: 'Une organisation manque à votre région?',
    next: 'Prochaine région',
    suggest: 'Faites une suggestion',
    orgRegion: 'Organisation dans la région | Organisation dans la région | Organisations dans la région',
    startupsRegion: 'jeune pousse présente dans la région | jeune pousse présente dans la région | jeunes pousses présentes dans la région',
    dynamic: 'Secteur le plus dynamique',
    solutionia: 'Entreprises offrant une solution d’IA',
    actorType: 'Types d\'acteurs',
    sectors: 'Secteur d\'application',
    small: 'petite | petite | petites',
    medium: 'moyenne | moyenne | moyennes',
    big: 'grande | grande | grandes',
    regional: {
      title: 'Portrait de l’IA dans la grande région de Québec',
      subtitle: 'Obtenez un portrait concis et rapide des données de notre répertoire sur les organisations présentes dans l’écosystème en IA de la Capitale-Nationale et de Chaudière-Appalaches.',
      notes: `<p>Cette vitrine a été élaborée en partenariat avec l’agence de développement économique <b>Québec International</b> et <b>l’Institut intelligence et données (IID)</b> de l’Université Laval, fiers contributeurs de l’essor du secteur de l’intelligence artificielle dans le Grand Québec.</p><p>Vous travaillez en IA et votre entreprise ne figure pas sur la vitrine ? Créez votre profil et faites-vous connaître !</p>`,
      total: 'Total des organisations des 2 régions',
      seeAlso: 'Consulter aussi :',
      allRegion: 'Explorer les résultats de toutes les régions',
      cta_consult: 'Consulter le site',
      cta_1_title: 'Votre entreprise souhaite démarrer un projet en IA?',
      cta_1_text: 'Québec International peut vous accompagner tout au long de votre processus de création.',
      cta_2_title: 'Vous avez un projet de recherche en IA en tête?',
      cta_2_text: 'L’Institut intelligence et données (IID) de l’Université Laval peut vous aider.'
    }
  }
}
