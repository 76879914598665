<template>
  <footer class="footer">
    <div class="footer__content">
      <div class="footer__column -smaller">
        <img :src="footer_logo_src" :alt="$t('app.ariaLabel.footerLogo')" class="footer__logo" />
        <RawHtml class="footer__richtext" :html="content.learnMore" />

        <Socials />
      </div>
      <nav class="footer__column -padding">
        <img src="/images/logo_gouv.png" :alt="$t('app.ariaLabel.partnerLogo')" class="footer__logo" />
        <h3 class="footer__subtitle">{{ $t('app.footer.partners') }}</h3>
        <RawHtml class="footer__richtext" :html="content.partners" />
      </nav>

      <div class="footer__column -smaller">
        <LangRouterLink v-if="this.$beet.options.general.video" class="footer__video-modal" :to="openVideoModal">
          <Icon id="miria_stars" />
          {{ content.textVideo }}
        </LangRouterLink>
      </div>
    </div>

    <div class="terms">
      <div>
        <p>
          © {{ currentYear }} - {{ $t('app.companyName') }}<span> - </span>
          <LangRouterLink class="terms__link" :to="{ name: 'terms' }">
            {{ $t('app.footer.terms') }}
          </LangRouterLink>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import { Socials } from '@/components/partials'

export default {
  name: 'SiteFooter',
  components: { Socials },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      isNewsLetterOpen: false
    }
  },
  computed: {
    content() {
      return this.$beet.options.footer
    },
    socials() {
      return this.$beet.options.socials
    },
    actors() {
      const { items = [] } = this.content.businessType || {}
      return Object.values(items).map(id => this.$beet.actorTypes[id])
    },
    categories() {
      const { items = [] } = this.content.businessCategory || {}
      return Object.values(items).map(id => this.$beet.categories[id])
    },
    footer_logo_src() {
      return `/images/footer_logo_${this.$lang.current}.png`
    },
    openVideoModal() {
      return {
        name: this.$route.name,
        query: { ...this.$route.query, video: true }
      }
    }
  }
}
</script>
