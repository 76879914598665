<template>
  <div :class="{ '-loading': !$page }">
    <template v-if="$page">
      <header class="header -notfound" :style="backgroundImage">
        <h2 v-scroll:reveal class="header__subtitle">{{ $page.header.subtitle }}</h2>
        <h1 v-scroll:reveal class="header__title">{{ $page.header.title }}</h1>
        <LangRouterLink :to="{ name: 'home' }" class="button -link -dark header__notfound-link">
          {{ $t('app.nav.backHome') }}
        </LangRouterLink>
      </header>
    </template>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  beetPage: 'notfound',
  computed: {
    backgroundImage() {
      return this.$device.tablet
        ? `background-image: url('/images/banner_404.png')`
        : `background-image: url('/images/banner_404_m.png')`
    }
  }
}
</script>
