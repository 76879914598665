import Vue from 'vue'
import VueRouter from 'vue-router'
import LangRouter from '@boite-beet/lang-router'
import store from '@/store'
import {
    BusinessIndex,
    BusinessShow,
    DocumentIndex,
    Glossary,
    Home,
    Instant,
    InstantRegion,
    Maturity,
    MaturityForm,
    MaturityReport,
    MaturityReportComplete,
    NotFound,
    SpaceForm,
    StudyIndex,
    StudyShow,
    Terms,
} from '@/views'

Vue.use(VueRouter)
Vue.use(LangRouter, {store})

const reroute = [{path: '/repertoire', redirect: '/'}, {path: '/repertoire/coupdoeil', redirect: '/'}]

const routes = LangRouter.createRoutes('fr', 'en')(
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: {en: 'directory', fr: 'repertoire'},
        name: 'businessIndex',
        component: BusinessIndex
    },
    {
        path: {en: 'directory/overview', fr: 'repertoire/coupdoeil'},
        name: 'instant',
        component: Instant
    },
    {
        path: {en: 'directory/quebec-city-area', fr: 'repertoire/region-de-quebec'},
        name: 'instantRegion',
        component: InstantRegion
    },
    {
        path: {en: 'businesses-directory/:businessSlug', fr: 'repertoire-entreprises/:businessSlug'},
        name: 'businessShow',
        component: BusinessShow,
        props: true
    },
    {
        path: {en: '/reference-documents', fr: '/documents-de-reference'},
        name: 'documentIndex',
        component: DocumentIndex
    },
    {
        path: {en: 'glossary', fr: 'glossaire'},
        name: 'glossary',
        component: Glossary
    },
    {
        path: {en: 'studies', fr: 'etudes'},
        name: 'studyIndex',
        component: StudyIndex
    },
    {
        path: {en: 'studies/:studySlug', fr: 'etudes/:studySlug'},
        name: 'studyShow',
        component: StudyShow,
        props: true
    },
    {
        path: {en: 'maturity', fr: 'maturite'},
        name: 'maturity',
        component: Maturity
    },
    {
        path: {en: 'maturity/form', fr: 'maturite/formulaire'},
        name: 'maturityForm',
        component: MaturityForm
    },
    {
        path: {en: 'maturity/report', fr: 'maturite/rapport'},
        name: 'maturityReport',
        component: MaturityReport
    },
    {
        path: {en: 'maturity/complete-report', fr: 'maturite/rapport-complet'},
        name: 'maturityReportComplete',
        component: MaturityReportComplete
    },
    {
        path: {en: '/maturity-results', fr: '/resultats-de-maturite'},
        beforeEnter(to, from, next) {
            next({name: 'maturityReportComplete', query: to.query})
        }
    },
    {
        path: {en: 'vitrine-space/form/:businessSlug?', fr: 'espace-vitrine/formulaire/:businessSlug?'},
        name: 'spaceForm',
        component: SpaceForm
    },
    {
        path: {en: 'privacy', fr: 'vieprivee'},
        name: 'terms',
        component: Terms
    },
    {
        path: '/404',
        name: 'not-found',
        component: NotFound
    },
    {
        path: '*',
        name: 'catch-all',
        component: NotFound
    },
    ...reroute,
)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: '-active',
    routes
})

export default router
